var $ = require("jquery");

var PaymentUtility = require("./_payment.utility");

var PaymentProcessView = Backbone.View.extend({
  events: {
    "click [data-payment-process]": "processSelection"
  },

  initialize: function(options) {
    var self = this;

    this.dispatcher = options.dispatcher;
    this.paymentMethodTypes = new PaymentUtility.PaymentMethodTypeCollection();

    _.each(options.paymentMethodTypes, function(type) {
      self.paymentMethodTypes.add(type);
    });

    this.dispatcher.on(
      "paymentOption:changed",
      this.processPaymentOptionChange.bind(this)
    );

    this.dispatcher.on(
      "initFromBillingInfo",
      this.initFromBillingInfo.bind(this)
    );
  },

  initFromBillingInfo: function(data) {
    this.processPaymentOptionChange(
      PaymentUtility.buildPaymentOptionChangeDataFromBillingInfo(
        data.billingInfo
      ),
      true,
      data.billingInfo
    );
  },

  processPaymentOptionChange: function(data, isInitializing, billingInfo) {
    var self = this;
    var allowManual = false;
    var allowElectronic = false;
    var initializing = isInitializing || false;

    this.selectedPaymentOption = data.paymentOption;

    this.$("[data-payment-process-container]").addClass("is-hidden");

    _.each(this.$("[data-payment-process]"), function(el) {
      el.checked = false;
      $(el)
        .prop("disabled", true)
        .addClass("is-hidden");
    });

    if (data.paymentOption) {
      var associations = data.paymentOption.get("payment_method_associations");

      _.each(associations, function(assoc) {
        var method = self.paymentMethodTypes.get({
          id: assoc.payment_method_type_id
        });

        if (method) {
          if (method.get("allow_manual")) {
            allowManual = true;
          }

          if (method.get("allow_electronic")) {
            allowElectronic = true;
          }
        }
      });

      let minimumThreshold = data.allowZero ? 0 : 1;
      let currentAmount = data.allowZero
        ? this.dispatcher.settings.totalAmount
        : data.amount;

      // we update the global currentAmount to easy process fees
      this.dispatcher.settings.currentAmount = data.amount;

      if (allowManual && allowElectronic && currentAmount >= minimumThreshold) {
        this.$("[data-payment-process]")
          .prop("disabled", false)
          .removeClass("is-hidden");

        this.$("[data-payment-process-container]").removeClass("is-hidden");

        if (!initializing) {
          this.announceChange(null);
        }
      } else if (allowElectronic && currentAmount >= minimumThreshold) {
        this.$("[data-payment-process-type-electronic]")
          .prop("disabled", false)
          .prop("checked", true);

        if (!initializing) {
          this.$("[data-payment-process-type-electronic]").trigger("click");
        }
      } else if (
        allowManual &&
        (currentAmount >= minimumThreshold ||
          data.paymentOption.get("name") === "no_payment")
      ) {
        this.$("[data-payment-process-type-manual]")
          .prop("disabled", false)
          .prop("checked", true)
          .removeAttr("data-dialog-trigger");

        if (!initializing) {
          this.$("[data-payment-process-type-manual]").trigger("click");
        }
      } else if (!initializing) {
        this.announceChange(null);
      }
    } else if (!initializing) {
      this.announceChange(null);
    }

    if (initializing && billingInfo.payment_method.component_id) {
      // checking if manual is the only process available,
      // if it's force to display available methods
      if (
        !billingInfo.payment_method.allow_electronic &&
        billingInfo.payment_method.allow_manual
      ) {
        billingInfo.is_manual = true;
      }

      if (billingInfo.is_manual) {
        this.$("[data-payment-process-type-manual]").prop("checked", true);
      } else {
        this.$("[data-payment-process-type-electronic]").prop("checked", true);
      }
    }
  },

  processSelection: function(event) {
    this.announceChange(parseInt($(event.currentTarget).val()));
  },

  announceChange: function(selection) {
    this.dispatcher.trigger(
      "paymentProcess:changed",
      PaymentUtility.buildPaymentProcessChangeData(
        this.selectedPaymentOption,
        selection
      )
    );

    const cancelManualProcess = $(document).find("[dialog-trigger-cancel]");
    if (
      cancelManualProcess.length &&
      this.selectedPaymentOption.get("name") !== "no_payment"
    ) {
      $(document)
        .find("[data-payment-process-type-manual]")
        .attr("data-dialog-trigger", "process_manual_payment");
      cancelManualProcess.removeClass("is-cancel");
    }
  }
});

module.exports = PaymentProcessView;
